<template>
  <img src="/images/show-placeholder.png" alt="Placeholder" width="100" height="100" class="placeholer-image">
</template>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.placeholer-image {
  background-color: var(--color-grey-dark);
}
</style>
